<div *ngIf="dataSource">

  <div class="example-container mat-elevation-z8">
    <uni-box>
      <mat-table #table [dataSource]="dataSource">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container [matColumnDef]="columns.name">
          <mat-header-cell *matHeaderCellDef> {{ 'table.' + columns.name | translate }}

            <div [matMenuTriggerFor]="menuNameColumn" class="uni-table-filter-header">
              <uni-icon [isLeftSpace]="true" [style]="hasNameFilter ? 'fas' : 'far'" name="filter"></uni-icon>
            </div>
            <mat-menu #menuNameColumn>
              <div class="mat-menu-autosize" style="width: 270px;">
                <div [disableRipple]="true" class="menu-title" mat-menu-item>
                  <uni-text [color]="'dark'" [weight]="'medium'">
                    {{ 'table.' + columns.name | translate }}
                  </uni-text>
                </div>
                <div (click)="$event.stopPropagation();" [disableRipple]="true" mat-filter-item mat-menu-item>
                  <mat-form-field>
                    <i aria-hidden="true" class="fa fa-search"></i>
                    <input [(ngModel)]="nameFieldFilterValue" matInput placeholder="Value">
                  </mat-form-field>
                </div>

                <div [disableRipple]="true" mat-filter-item mat-menu-item>
                  <uni-button (click)="applyFilter()">
                    {{ 'label.apply' | translate }}
                  </uni-button>
                </div>

                <div [disableRipple]="true" mat-filter-item mat-menu-item>
                  <uni-button (click)="clearFilter()" [style]="'link'">
                    {{ 'label.clear' | translate }}
                  </uni-button>
                </div>

              </div>
            </mat-menu>

          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.flowName }} </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container [matColumnDef]="columns.trigger">
          <mat-header-cell *matHeaderCellDef> {{ 'table.' + columns.trigger | translate }}


            <div [matMenuTriggerFor]="menuTriggerTypeColumn" class="uni-table-filter-header">
              <uni-icon [isLeftSpace]="true" [style]="hasTriggerFilter ? 'fas' : 'far'" name="filter"></uni-icon>
            </div>
            <mat-menu #menuTriggerTypeColumn>
              <div class="mat-menu-autosize" style="width: 270px;">

                <div [disableRipple]="true" class="menu-title" mat-menu-item>
                  <uni-text [color]="'dark'" [weight]="'medium'">
                    {{ 'table.' + columns.trigger | translate }}
                  </uni-text>
                </div>
                <div (click)="$event.stopPropagation();" [disableRipple]="true" mat-filter-item mat-menu-item>
                  <mat-form-field>
                    <mat-select [(ngModel)]="selectedTrigger" [panelClass]="'mat-elevation-z10'"
                                placeholder='Select Tigger'>
                      <mat-option *ngFor="let  triggerType of triggerTypes" [value]="triggerType">{{triggerType}}
                      </mat-option>
                    </mat-select>

                  </mat-form-field>
                </div>

                <div [disableRipple]="true" mat-filter-item mat-menu-item>
                  <uni-button (click)="applyFilter()">
                    {{ 'label.apply' | translate }}
                  </uni-button>
                </div>

                <div [disableRipple]="true" mat-filter-item mat-menu-item>
                  <uni-button (click)="clearFilter()" [style]="'link'">
                    {{ 'label.clear' | translate }}
                  </uni-button>
                </div>

              </div>
            </mat-menu>
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ getTriggerType(element.triggerType) }} </mat-cell>
        </ng-container>

        <!-- Weight Column -->
        <ng-container [matColumnDef]="columns.created">
          <mat-header-cell *matHeaderCellDef> {{ 'table.' + columns.created | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.createdAt | dateFormat }} </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="columns.version">
          <mat-header-cell *matHeaderCellDef> {{ 'table.' + columns.version | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.version }} </mat-cell>
        </ng-container>

        <ng-container [matColumnDef]="columns.status">
          <mat-header-cell *matHeaderCellDef> {{ 'table.' + columns.status | translate }} </mat-header-cell>
          <mat-cell *matCellDef="let element">
          <span [ngClass]="{
            'draft-status':element.flowStatus === 'DRAFT',
            'finalising-status':element.flowStatus === 'FINALISING',
            'active-status':element.flowStatus === 'ACTIVE',
            'inactive-status':element.flowStatus === 'INACTIVE'
          }" class="badge border">
            {{element.flowStatus}}</span>
          </mat-cell>
          
        </ng-container>

        <ng-container [matColumnDef]="columns.actions">
          <mat-header-cell *matHeaderCellDef>{{ 'label.action' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="ml-1 mr-3">
              <uni-link [path]="'flows/flow-builder?flowId=' + element.flowId + '&flowVersion=' + element.version + ''">
                <uni-icon [name]="'edit'"></uni-icon>
              </uni-link>
            </div>
            <div class="mr-3">
              <uni-icon (click)="getVersionedData(element)"
                        [class.example-expanded-row]="expandedElement?.flowId === element.flowId"
                        [name]="'fas fa-layer-group'"
                        [style]="'fas'"></uni-icon>
            </div>

            <uni-icon [matMenuTriggerFor]="menu" [name]="'ellipsis-v'"></uni-icon>
            <mat-menu #menu="matMenu" class="pl-4 pr-4">
              <div class="mat-menu-item-div d-flex justify-content-around align-items-center">
                <uni-icon [name]="'fas fa-edit'" [style]="'fas'"></uni-icon>
                <button mat-menu-item>
                  <a [href]="'flows/flow-builder?flowId=' + element.flowId + '&flowVersion=' + element.version" >
                    {{ 'label.edit' | translate }}
                  </a>
                </button>
              </div>
              <div class="mat-menu-item-div d-flex justify-content-around align-items-center">
                <uni-icon [name]="'fas fa-layer-group'" [style]="'fas'"></uni-icon>
                <button class="btn" (click)="openFlowVersionListModal(element.flowId,element.flowName)" mat-menu-item>
                  {{ 'label.version' | translate }}
                </button>
              </div>
              <div class="mat-menu-item-div d-flex justify-content-around align-items-center">
                <uni-icon [name]="'fas fa-clone'" [style]="'fas'"></uni-icon>
                <button mat-menu-item>
                  <a [href]="'flows/flow-builder?flowId=' + element.flowId +
                  '&isDuplicate=true&flowVersion=' + element.version + ''">
                  {{ 'label.duplicate' | translate }}
                  </a>
                </button> 
              </div>
              <div class="mat-menu-item-div d-flex justify-content-around align-items-center">
                <uni-icon [name]="'fas fa-trash'" [style]="'fas'"></uni-icon>
                <button (click)="openConfirmModal(element.flowId, element.version)" mat-menu-item>
                  {{ 'label.delete' | translate }}
                </button>
              </div>
              <div *ngIf="element.flowStatus === 'ACTIVE'" class="mat-menu-item-div d-flex justify-content-around align-items-center">
                <uni-icon [name]="'fas fa-layer-group'" [style]="'fas'"></uni-icon>
                <button (click)="undeployFlow(element)" mat-menu-item> 
                  {{ 'label.stopFlow' | translate }}
                </button>
              </div>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column -->
        <ng-container matColumnDef="expandedDetail">
          <mat-cell *matCellDef="let detail">
            <div class="example-element-detail">
              <uni-section>
                <uni-box>
                  <div *ngIf="versionedFlows.length" class="div-table">

                    <ng-container *ngFor="let versionedFlow of versionedFlows">
                      <mat-grid-list cols="5" rowHeight="50px">
                        <mat-grid-tile>
                          <div class=" flow-name">
                            {{flowName}} v {{versionedFlow.flowVersion}}
                          </div>
                        </mat-grid-tile>
                        <mat-grid-tile>
                          <div class=" version-date">
                            {{versionedFlow.createdAt | date : 'dd-MM-yyyy'}}
                          </div>
                        </mat-grid-tile>
                        <mat-grid-tile>
                          <div class=" version-date">{{versionedFlow.createdAt | date : 'HH:mm'}}</div>
                        </mat-grid-tile>
                        <mat-grid-tile>
                          <div class="version-status">
                          <span [ngClass]="{
                                                        'draft-status':versionedFlow.flowStatus === 'DRAFT',
                                                        'finalising-status':versionedFlow.flowStatus === 'FINALISING'
                                                      }" class="badge border ">
                            {{versionedFlow.flowStatus}}</span>
                          </div>
                        </mat-grid-tile>
                        <mat-grid-tile>
                          <div class="">
                            <uni-link
                              [path]="'flows/flow-builder?flowId=' + expandedElement?.flowId + '&flowVersion=' + versionedFlow?.flowVersion + ''">
                              <uni-icon [name]="'edit'"></uni-icon>
                            </uni-link>
                          </div>
                        </mat-grid-tile>
                      </mat-grid-list>
                    </ng-container>
                  </div>
                  <div *ngIf="versionedFlows.length == 0" class="div-table text-center">
                    <h4>You don't have versions created for this flow</h4>
                  </div>

                </uni-box>
              </uni-section>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" [class.expanded]="expandedElement == row"
                 class="element-row"
                 matRipple>
        </mat-row>
        <mat-row *matRowDef="let row;
        columns: ['expandedDetail']; when: isExpansionDetailRow"
                 [@detailExpand]="row.element == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden">
        </mat-row>
      </mat-table>
    </uni-box>
  </div>

  <uni-pagination *ngIf="showPagination" [itemsPerPage]="itemsPerPage" [items]="totalItems"></uni-pagination>

  <uni-section>
    <div *ngIf="totalItems == 0" class="col-lg-12 col-md-12 col-sm-12 pt-4 rounded text-center no-flow">
      <div class="pt-4">
        <uni-text-caption [isUppercase]="false">{{ 'label.noFlow' | translate}}</uni-text-caption>
      </div>
      <div class="pt-4">
        <img alt="No Flows" src="assets/img/no-flows.png">
      </div>
    </div>
  </uni-section>

  <fs-flows-list-modal (isActiveChange)="toggleModal()" (submitChange)="onRemove()" [isActive]="isModalActive">
  </fs-flows-list-modal>

  <fs-flow-version-list-modal (isActiveChange)="toggleFlowVersionListModal()" [flowId]="flowId" [flowName]="flowName"
                              [flowVersions]="flowVersions" [isActive]="isFlowVersionListModalActive">
  </fs-flow-version-list-modal>
</div>
