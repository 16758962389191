import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { WhatsappRepository } from '@flows/shared/whatsapp/whatsapp.repository';
import { of } from 'rxjs';

import * as WhatsappApplicationActions from './whatsapp.actions';
import { distinctUntilChanged, mergeMap } from 'rxjs/operators';

@Injectable()
export class WhatsappEffects {
  @Effect() setWhatsappApplications$ = this.actions$.pipe(
    ofType<WhatsappApplicationActions.SetWhatsappApplications>(WhatsappApplicationActions.SET_WHATSAPP_APPLICATIONS),
    mergeMap(() =>
      this.whatsappRepository.getWhatsappApplications().pipe(
        mergeMap((response) => {
          return of(new WhatsappApplicationActions.SetWhatsappApplicationsSuccess(response.body));
        })
      )
    )
  );
  @Effect() setWhatsappApplicationTemplates$ = this.actions$.pipe(
    ofType<WhatsappApplicationActions.SetWhatsappApplicationTemplates>(WhatsappApplicationActions.SET_WHATSAPP_APPLICATION_TEMPLATES),
    distinctUntilChanged((x, y) => x.applicationId === y.applicationId && x.channelId === y.channelId),
    mergeMap(({ applicationId, channelId }) =>
      this.whatsappRepository.getWhatsappApplicationTemplates(applicationId, channelId).pipe(
        mergeMap((response) => {
          return of(new WhatsappApplicationActions.SetWhatsappApplicationTemplatesSuccess(response.body));
        })
      )
    )
  );
  @Effect() setWhatsappApplicationKe$ = this.actions$.pipe(
    ofType<WhatsappApplicationActions.SetWhatsappApplicationKey>(WhatsappApplicationActions.SET_WHATSAPP_APPLICATION_KEY),
    distinctUntilChanged((x, y) => x.applicationId === y.applicationId),
    mergeMap(({ applicationId }) =>
      this.whatsappRepository.getWhatsappApplicationKey(applicationId).pipe(
        mergeMap((response) => {
          return of(new WhatsappApplicationActions.SetWhatsappApplicationKeySuccess(response.body));
        })
      )
    )
  );

  constructor(private actions$: Actions, private whatsappRepository: WhatsappRepository) {}
}
