export default class ValidationRules {
  public static getValidationRules() {
    const validationRulesByWidgetType = [];
    validationRulesByWidgetType.push(
      {
        type: 'FS_WAIT_FOR_WEBHOOK',
        requiredParams: ['wait_seconds'],
      },
      {
        type: 'FS_BRANCH',
        requiredParams: [],
      },
      {
        type: 'FS_GO_TO_FLOW',
        requiredParams: ['goto_flow_id'],
      },
      {
        type: 'FS_JUMP_TO',
        requiredParams: ['jump_to_max_loop_count'],
      },
      {
        type: 'FS_TIMER_WAIT',
        requiredParams: [],
      },
      {
        type: 'FS_END',
        requiredParams: [],
      },
      {
        type: 'FS_SMS',
        requiredParams: ['sms_application_id', 'sms_sender_id', 'sms_recipient', 'sms_message_text'],
      },
      {
        type: 'FS_API_CALL',
        requiredParams: ['api_call_url'],
      },
      {
        type: 'FS_WHATSAPP',
        requiredParams: ['whatsapp_template_name', 'whatsapp_template_namespace', 'templateLanguage', 'whatsapp_recipient'],
      },
      {
        type: 'FS_TIMER_WAIT',
        requiredParams: [],
      },
      {
        type: 'FS_VOICE',
        requiredParams: ['voice_call_destination', 'voice_application_name'],
      },
      {
        type: 'FS_PLAY_AUDIO',
        requiredParams: [
          'voice_play_audio_loop_count',
          // 'voice_play_audio_name'
        ],
      },
      {
        type: 'FS_VOICE_COLLECT_INPUT',
        requiredParams: ['voice_collect_wait_for_response_seconds'],
      },
      {
        type: 'FS_NOTIFICATION',
        requiredParams: ['notification_title', 'notification_service_id', 'notification_token'],
      },
      {
        type: 'FS_EMAIL',
        requiredParams: ['from_email', 'to_email', 'email_subject', 'email_content', 'email_content_type'],
      }
    );

    return validationRulesByWidgetType;
  }

  public static getGenericBranchOptions() {
    return [
      'FS_BRANCH',
      'FS_DATE_TIME',
      'FS_JAVASCRIPT',
      'FS_EMAIL',
      'FS_GO_TO_FLOW',
      'FS_JUMP_TO',
      'FS_TIMER_WAIT',
      'FS_END',
      'FS_API_CALL',
      'FS_WAIT_FOR_WEBHOOK',
      'FS_SMS',
      'FS_WHATSAPP',
      'FS_VOICE',
      'FS_PLAY_AUDIO',
      'FS_VOICE_COLLECT_INPUT',
      'FS_VOICE_FORWARD_CALL',
      'FS_VOICE_END_CALL',
      'FS_NOTIFICATION',
    ];
  }

  public static getMakeCallBranchOptions() {
    return ['FS_PLAY_AUDIO', 'FS_VOICE_COLLECT_INPUT'];
  }

  public static getMakeCallCompletedIfOptions() {
    return ['FS_PLAY_AUDIO', 'FS_VOICE_COLLECT_INPUT', 'FS_VOICE_FORWARD_CALL', 'FS_VOICE_END_CALL'];
  }

  public static getMakeCallCompletedElseOptions() {
    return [
      'FS_VOICE',
      'FS_BRANCH',
      'FS_DATE_TIME',
      'FS_JAVASCRIPT',
      'FS_EMAIL',
      'FS_GO_TO_FLOW',
      'FS_JUMP_TO',
      'FS_TIMER_WAIT',
      'FS_END',
      'FS_API_CALL',
      'FS_WAIT_FOR_WEBHOOK',
      'FS_SMS',
      'FS_WHATSAPP',
    ];
  }

  public static getMakeCallFailedIfOptions() {
    return [
      'FS_VOICE',
      'FS_BRANCH',
      'FS_DATE_TIME',
      'FS_JAVASCRIPT',
      'FS_EMAIL',
      'FS_GO_TO_FLOW',
      'FS_JUMP_TO',
      'FS_TIMER_WAIT',
      'FS_END',
      'FS_API_CALL',
      'FS_WAIT_FOR_WEBHOOK',
      'FS_SMS',
      'FS_WHATSAPP',
    ];
  }

  public static getMakeCallFailedElseOptions() {
    return ['FS_PLAY_AUDIO', 'FS_VOICE_COLLECT_INPUT', 'FS_VOICE_FORWARD_CALL', 'FS_VOICE_END_CALL'];
  }
}
