<uni-loader *ngIf="(isLoaderActive$ | async)"></uni-loader>
<uni-container>
  <uni-section [isFirst]="true">
    <uni-title-container>
      <uni-title-item>
        <uni-icon
        routerLink="/"
        name="angle-left"
        [isRightSpace]="true">
      </uni-icon>
        <uni-text-body>
          {{ 'flows.list.title' | translate }}
          <ng-container>
            ({{totalItems}} {{ 'label.flows' | translate }})
          </ng-container>
        </uni-text-body>
      </uni-title-item>
      <uni-title-item>
        <uni-button (click)="onCreateFlow()">
          {{ 'flows.list.create' | translate }}
          <uni-icon-action></uni-icon-action>
        </uni-button>
      </uni-title-item>
    </uni-title-container>
    <uni-separator></uni-separator>
  </uni-section>
  <uni-section>
    <div class="">
      <div class="">
        <uni-box-tabs>
          <uni-box-tab (click)="changeTab('ALL')" [isActive]="activeTab === 'ALL'">
            {{ 'label.all' | translate }}
          </uni-box-tab>
          <uni-box-tab (click)="changeTab('ACTIVE')" [isActive]="activeTab === 'ACTIVE'">
            {{ 'label.active' | translate }}
          </uni-box-tab>
          <uni-box-tab (click)="changeTab('DRAFT')" [isActive]="activeTab === 'DRAFT'">
            {{ 'label.draft' | translate }}
          </uni-box-tab>
          <uni-box-tab (click)="changeTab('INACTIVE')" [isActive]="activeTab === 'INACTIVE'">
            {{ 'label.inactive' | translate }}
          </uni-box-tab>
        </uni-box-tabs>
      </div>
    </div>
    <!-- <fs-flows-list-tabs></fs-flows-list-tabs> -->
    <fs-flows-list-table [params]="queryParams" [showPagination]="true"></fs-flows-list-table>
  </uni-section>
</uni-container>
