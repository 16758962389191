// https://unifonic.atlassian.net/wiki/spaces/DESIGN/pages/2301952090/Data+formats#Standard.1
import { Injectable } from '@angular/core';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class UniNumberService {
  readonly trill = 1000000000000;
  readonly bil   = 1000000000;
  readonly mln   = 1000000;
  readonly thous = 1000;

  constructor(
    private translate: TranslateService,
  ) { }

  formatNumber(value: number, fractionDigits?: number): string {
    const digitsInfo = this.getDigitsInfo(fractionDigits);

    return formatNumber(value, 'en', digitsInfo);
  }

  formatShortNumber(value: number, fractionDigits?: number): string {
    let shortValue = value;
    let i18nKey = '';

    if (value >= this.trill) {
      shortValue = value / this.trill;
      i18nKey = 'format.trillion';
    } else if (value >= this.bil) {
      shortValue = value / this.bil;
      i18nKey = 'format.billion';
    } else if (value >= this.mln) {
      shortValue = value / this.mln;
      i18nKey = 'format.million';
    } else if (value >= this.thous) {
      shortValue = value / this.thous;
      i18nKey = 'format.thousand';
    } else {
      return this.formatNumber(value, fractionDigits);
    }

    return this.translate.instant(
      i18nKey,
      { number: this.formatNumber(shortValue, fractionDigits) });
  }

  // formatLongNumber(value: number, fractionDigits?: number): string {}

  private getDigitsInfo(fractionDigits?: number): string {
    return fractionDigits ? `1.${fractionDigits}-${fractionDigits}` : '1.0-2';
  }
}
