import {PrimaryCaller, VoiceApplicationModel, VoiceAudioModel} from '@flows/shared/voice/voice.model';

export const VOICE_KEY = 'voice';
export interface VoiceState {
  voiceApplicationModel: VoiceApplicationModel[];
  voiceAudioModel: VoiceAudioModel[];
  primaryCaller: PrimaryCaller[];
}

export const initialVoiceState: VoiceState = {
  voiceApplicationModel: [],
  voiceAudioModel: [],
  primaryCaller: []
};
