import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UniNumberService } from '../../../shared/uni-number.service';

@Injectable()
export class UniPriceService {
  constructor(
    private translate: TranslateService,
    private uniNumberService: UniNumberService,
  ) {}

  getPrice(amount: number, currency: string, fractionDigits?: number): string {
    return this.translate.instant('format.money2', {
      currency: this.getCurrency(currency),
      amount: this.uniNumberService.formatNumber(amount, fractionDigits),
    });
  }

  getShortPrice(amount: number, currency: string, fractionDigits?: number): string {
    return this.translate.instant('format.money2', {
      currency: this.getCurrency(currency),
      amount: this.uniNumberService.formatShortNumber(amount, fractionDigits),
    });
  }

  private getCurrency(currency: string): string {
    return this.translate.instant(`currency.${currency}`);
  }
}
