import { Params } from '@angular/router';

import {VoiceAudioModel, VoiceApplicationModel, PrimaryCaller} from '@flows/shared/voice/voice.model';
import {Action, UPDATE} from '@ngrx/store';

export const SET_VOICE_AUDIO_URLS = '[Voice] SET_VOICE_AUDIO_URLS';
export const SET_VOICE_AUDIO_URLS_SUCCESS = '[Voice] SET_VOICE_AUDIO_URLS_SUCCESS';
export const SET_VOICE_APPLICATIONS = '[Voice] SET_VOICE_APPLICATIONS';
export const SET_VOICE_APPLICATIONS_SUCCESS = '[Voice] SET_VOICE_APPLICATIONS_SUCCESS';
export const SET_INBOUND_CALLER_IDS = '[Voice] SET_INBOUND_CALLER_IDS';
export const SET_INBOUND_CALLER_IDS_SUCCESS = '[Voice] SET_INBOUND_CALLER_IDS_SUCCESS';


export class SetVoiceAudioUrls implements Action {
  readonly type = SET_VOICE_AUDIO_URLS;
  constructor() { }
}
export class SetVoiceAudioUrlsSuccess implements Action {
  readonly type = SET_VOICE_AUDIO_URLS_SUCCESS;
  constructor(public response: VoiceAudioModel[]) { }
}

export class SetVoiceApplications implements Action {
  readonly type = SET_VOICE_APPLICATIONS;
  constructor() { }
}
export class SetVoiceApplicationsSuccess implements Action {
  readonly type = SET_VOICE_APPLICATIONS_SUCCESS;
  constructor(public response: VoiceApplicationModel[]) { }
}

export class SetInboundCallerIds implements Action {
  readonly type = SET_INBOUND_CALLER_IDS;
  constructor() { }
}
export class SetInboundCallerIdsSuccess implements Action {
  readonly type = SET_INBOUND_CALLER_IDS_SUCCESS;
  constructor(public response: PrimaryCaller[]) { }
}

export type All =
  | SetVoiceAudioUrls
  | SetVoiceAudioUrlsSuccess
  | SetVoiceApplications
  | SetVoiceApplicationsSuccess
  | SetInboundCallerIds
  | SetInboundCallerIdsSuccess
  ;
