export interface AudienceEventModel {
  total: number;
  eventTypes: EventType[];
}

export interface EventType {
  id: string;
  name: string;
}

export interface AudienceSourceModel {
  total: number;
  eventSources: EventSource[];
}

export interface EventSource {
  id: string;
  name: string;
  description: string;
  channel: string;
  timestamp: string;
  enabled: boolean;
  transitional: boolean;
  returnsProfile: boolean;
  permanentProfileId: boolean;
  requiresConsent: boolean;
  locked: boolean;
  synchronizeProfiles: boolean;
  type: string[];
  tags: string[];
  groups: string[];
  bridge: Bridge;
}

export interface Bridge {
  id: string;
  name: string;
}

export enum AudienceQueryNames {
  GetEventList = 'getEventList',
  GetSourceList = 'getSourceList',
  GetPropertyList = 'getPropertyList',
  GetAudienceMappings = 'getAudienceMappings',
}

export interface AudiencePropertiesModel {
  properties: string[];
  groups: PropertyGroups[];
  items: Record<
    string,
    Record<
      string,
      | string
      | {
          mapping: string;
          type: AttributeTypesEnum;
        }
    >
  >;
}

export enum AttributeTypesEnum {
  Date = 'DATE',
  Text = 'TEXT',
  Number = 'NUMBER',
  Boolean = 'BOOLEAN',
}

export interface PropertyGroups {
  name: string;
  properties: string[];
}

export interface AudienceMapping {
  id: string;
  name: string;
  description: string;
  fields: ProfileField[];
  identifications: IdentificationField[];
  enabled: boolean;
  tags: string[];
  eventType: EventType;
  source?: MappingSource;
}

export interface ProfileField {
  eventProperty: string;
  profileProperty?: string;
  identification: boolean;
  trait: boolean;
}

export type IdentificationField = Omit<ProfileField, 'identification' | 'trait'>;

export interface EventType {
  id: string;
  name: string;
}

export type MappingSource = EventType;

export interface GetMappingsResponse {
  total: number;
  count: number;
  mappings: AudienceMapping[];
}
